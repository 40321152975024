<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <div class="headTil">基础</div>
        <el-form-item label="规格名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="供应商名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="智能取餐柜货架数量" prop="shelfNum">
          <el-input-number
            controls-position="right"
            v-model="dataForm.shelfNum"
            :disabled="disabled"
            size="medium"
            placeholder="智能取餐柜货架数量"
          ></el-input-number>
        </el-form-item>
        <div class="headTil">串口参数</div>
        <el-form-item label="波特率" prop="baudRate">
          <el-input-number
            controls-position="right"
            v-model="dataForm.baudRate"
            :disabled="disabled"
            size="medium"
            placeholder="波特率"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="校验位" prop="parity">
          <el-select
            v-model="dataForm.parity"
            :disabled="disabled"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in parityList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="数据位" prop="dataBits">
          <el-input-number
            controls-position="right"
            v-model="dataForm.dataBits"
            :disabled="disabled"
            data-unit="bit"
            size="medium"
            class="my-el-input-number"
            placeholder="数据位"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="停止位" prop="stopBits">
          <el-input-number
            controls-position="right"
            v-model="dataForm.stopBits"
            :disabled="disabled"
            data-unit="bit"
            size="medium"
            class="my-el-input-number"
            placeholder="停止位"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="读取超时" prop="readTimeout">
          <el-input-number
            controls-position="right"
            v-model="dataForm.readTimeout"
            :disabled="disabled"
            data-unit="ms"
            size="medium"
            class="my-el-input-number"
            placeholder="读取超时"
          ></el-input-number>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        name: '',
        shelfNum: '',
        baudRate: '',
        parity: '',
        dataBits: '',
        stopBits: '',
        readTimeout: '',
      },
      parityList: [
        { id: 0, name: '无' },
        { id: 1, name: '奇校验位' },
        { id: 2, name: '偶校验位' },
      ],
      dataRule: {
        name: [
          { required: true, message: '规格名字不能为空', trigger: 'blur' },
        ],
        shelfNum: [
          {
            required: true,
            message: '智能取餐柜货架数量不能为空',
            trigger: 'blur',
          },
        ],
        baudRate: [
          { required: true, message: '波特率不能为空', trigger: 'blur' },
        ],
        parity: [
          { required: true, message: '校验位不能为空', trigger: 'blur' },
        ],
        dataBits: [
          { required: true, message: '数据位不能为空', trigger: 'blur' },
        ],
        stopBits: [
          { required: true, message: '停止位不能为空', trigger: 'blur' },
        ],
        readTimeout: [
          { required: true, message: '读取超时不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    init(data, disabled) {
      this.disabled = disabled;
      this.visible = true;
      if (data !== null) {
        this.dataForm = data;
      } else {
        this.dataForm = {};
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/freezer-spec/${!this.dataForm.id ? 'save' : 'id/update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.headTil {
  font-size: 15px;
  color: #6e6c6b;
  font-weight: 600;
  line-height: 40px;
  width: 100%;
  height: 40px;
}
.my-el-input-number[data-unit] {
  --el-input-number-unit-offset-x: 50px;
  position: relative;
}
.my-el-input-number[data-unit]::after {
  content: attr(data-unit);
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: var(--el-input-number-unit-offset-x);
  color: #6e6c6b;
}
.my-el-input-number[data-unit] .el-input__inner {
  padding-left: 30px;
  padding-right: calc(var(--el-input-number-unit-offset-x) + 12px);
}
</style>
